<template>
    <v-container fluid fill-height>
        <div class="hero"></div>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card elevation="2" class="form">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ $t('views.inloggen') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                prepend-icon="person"
                                name="login"
                                v-model="username"
                                label="Login"
                                type="text"
                                v-on:keyup.enter="login"
                                :error-messages="this.$store.state.loginErrors.username"
                            />
                            <v-text-field
                                id="password"
                                prepend-icon="lock"
                                v-model="password"
                                name="password"
                                label="Password"
                                type="password"
                                v-on:keyup.enter="login"
                                :error-messages="this.$store.state.loginErrors.password"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="accent"
                            elevation="2"
                            x-large
                            block
                            @click.prevent="login"
                        >Login</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                      <a style="float: right; padding: 10px;" href="/forgot-password">{{ $t('interface.forgot_password') }}</a>
                    </v-card-actions>
                </v-card>
                <v-alert
                  :value="show_error"
                  type="error"
                >
                    {{ login_error }}
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "LoginForm",
        data () {
            return {
                username: "",
                password: "",
                login_error: "",
                show_error: false
            }
        },
        methods: {
            login: function () {
                this.show_error = false
                this.$store.dispatch('login', { 
                    username: this.username.trim(),
                    password: this.password.trim()
                })
                .then(() => {
                    this.$router.push({'name': 'dashboard', 'params' : {'lang': this.$store.state.language}})
                })
                .catch(err => {
                    this.login_error = this.$t('errors.login.title')
                    if (this.$store.state.loginErrors.non_field_errors) {
                      this.login_error += ": " + this.$t('errors.login.invalid')
                    }
                    else {
                      this.login_error += ": " + this.$t('errors.login.emptyfield')
                    }
                    this.$store.state.loginErrors,
                    this.show_error = true
                })
            }
        },
        beforeCreate() {
            if (this.$store.state.token && this.$store.state.language) {
                this.$router.push({'name': 'dashboard', 'params' : {'lang': this.$store.state.language}})
            }
        }
    }
</script>

<style>
.hero {
    background: url('/img/bevlogenheidsmeter-mobiel-nolost-mto.jpg');
    height: 100vh;
    width: 100vw;
    filter: blur(6px);
    transform: scale(1.1);
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    background-color: black;
}
</style>